<template>
	<div class="book-info-repository">
		<table-div
			:datas="tableData"
			:header="bookinfoColumns"
			:tdwidths="tdwidths"
		>
			<template #action="props">
				<div class="btnn-group">
					<div class="layer-2">
						<button
							class="btn btn-outline-secondary btn-sm"
							@click="check_create(props.item)"
						>
							借閱
						</button>
						<button
							class="btn btn-outline-secondary btn-sm"
							aria-label="閱讀(另開新分頁)"
							@click="readView(props.item)"
						>
							閱讀
						</button>
						<!--<button
							class="btn btn-outline-secondary btn-sm"
							@click="
							feedback_id = props.item;
							feedbackShow = true;
						">回報</button>-->
						<button
							class="btn btn-outline-secondary btn-sm"
							@click="
								$router.push({
									name: 'bookRepositoryDetail',
									params: { id: props.item }
								})
							"
						>
							詳目
						</button>
					</div>
				</div>
			</template>
			<template #finish_date="props">
				{{ showDate(props.item) }}
			</template>
		</table-div>
		<!--<modal v-if="feedbackShow" @closeModal="feedbackShow = false">
			<template slot="header">
				<h4 class="modal-title">書籍 {{ feedback_id }} 錯誤內容回報</h4>
			</template>
			<template slot="body">
				<h5>回報內容:</h5>
				<textarea class="feedback" 
					v-model="feedback_content"
				></textarea>
			</template>
			<template slot="footer">
				<button
					class="btn btn-outline-dark"
					@click="$refs[id].close()"
				>取消</button>
				<button
					class="btn btn-primary"
					@click="feedback()"
				>回報</button>
			</template>
		</modal>-->
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import tableDiv from "@/components/tableDiv.vue";
import { bookAction, libraryRecordAction } from "@/api/ebookSystem.js";
import { format, parse, addDays } from "date-fns";

export default {
	props: ["datas"],
	//mixins: [epubApp],
	components: {
		tableDiv
		//tableDivOrder,
	},
	data() {
		return {
			feedbackShow: false,
			feedback_id: "",
			feedback_content: ""
		};
	},
	computed: {
		...mapState("user", ["user"]),
		bookinfoColumns() {
			if (this.user.auth_guest) {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					author: "作者",
					house: "出版社",
					date: "出版日期",
					finish_date: "上架日期",
					action: "動作"
				};
			} else {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					author: "作者",
					house: "出版社",
					date: "出版日期",
					finish_date: "上架日期"
				};
			}
		},
		tableData() {
			return this.datas || [];
		},
		tdwidths() {
			if (this.user.auth_guest) {
				return [15, 20, 7, 10, 10, 9, 9, 20];
			} else {
				return [20, 25, 9, 11, 11, 12, 12];
			}
		}
	},
	methods: {
		...mapActions("epub", ["addLocalBooks"]),
		check_create(ISBN) {
			libraryRecordAction
				.checkCreate({ ISBN })
				.then(res => {
					return libraryRecordAction.checkInout({
						pk: res.data.id,
						action: "check_out"
					});
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功借閱書籍" });
				})
				.catch(err => {
					this.$errorHandler({ error: err });
				});
		},
		feedback() {
			bookAction
				.feedback(this.feedback_id, {
					content: this.feedback_content
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功回報資料" });
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		async readView(pk) {
			const epub = await bookAction.getEpub(pk);
			await this.addLocalBooks({ id: pk, url: epub });
			this.$root.$read.open({ url: `/Access8EpubWeb/book/${pk}` });
			// window.open(window.location.href, '_blank');
			// window.location.replace(`/Access8EpubWeb/book/${pk}`);
		},
		showDate(item) {
			try {
				return format(
					addDays(parse(item, "yyyy-MM-dd", new Date(0, 0, 0)), -5),
					"yyyy-MM-dd"
				);
			} catch (error) {
				return item;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.book-info-repository {
	margin: 0 -15px;
	.filter {
		display: flex;
		justify-content: flex-end;
		padding-right: 10px;
	}
	.feedback {
		width: fill-available;
		width: -webkit-fill-available;
		height: 200px;
		color: #4d4d4d;
		font-size: 1.2em;
	}

	// *Purpose: Fix a wierd bug that .btnn-group functioning out of expect,
	// the width is too small than expect, don't know why.
	::v-deep .table-div {
		.btnn-group {
			min-width: 4.4em;
		}
	}
}
</style>
